ul.nav-tabs {
	&.nav-justified {
		li {
			&.active {border-top:10px solid $primary !important; background:#FFF;
				a {color:$darkgrey; background:#FFF;
					span {color:$primary;}
				}
			}
			&:hover {border-top:10px solid $grey;}
			a {text-align:left; border-radius:0; background:$lightgrey ;@include primary-bold; color:#666;
				span {display:block;@include primary; text-transform:lowercase; font-size:24px;}
			}
		}
	}
	li {
		&.active {

		}
		a {border-radius:0; 
			
		}
	}
}
.tab-content {

}
@media (min-width: 768px) {
	.tab-content {
		&.tab-content-bordered {padding:40px; border:1px solid $grey; border-top:none;}
	}
}