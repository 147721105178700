.page-title-wrapper {position:relative; background-color:#000; padding:0px 0 0; background-size:cover; background-position:center center; overflow:hidden;background-repeat:no-repeat;
	.page-title {position:relative; background:rgba(0,0,0,0.7); border-top:1px solid rgba(255,255,255,0.2);
		h1, h2 {@include primary-light; margin:20px 0 0; padding:0; color:#FFF; font-size:24px;}
	}
}
ol.breadcrumb {background:none; padding:0px 0 10px; margin-bottom:10px;
	li {
		&:last-child {
			a {color: $blue;}
		}
		a {color:rgba($blue, 0.75); @include primary; font-size:14px;}
	}
}
@media (min-width: 768px) {
		.page-title-wrapper {padding:200px 0 0;
			.page-title {
				h1, h2 {position:absolute; font-size:36px; margin: -20px 0 0;}
			}
		}
		ol.breadcrumb {background:none; padding:20px 0 10px; margin-bottom:10px;}
}
@media (min-width: 992px) {
		.page-title-wrapper {padding:240px 0 0; background-size:contain; background-position:top center; overflow:hidden; background-attachment:fixed;
			.page-title {background-image:url(/_system/stylesheets/webart/branding/danish-mark.svg); background-repeat:no-repeat; background-attachment:fixed; background-position:right top;
				h1, h2 {font-size:40px; margin: -22px 0 0;}
			}
		}
}
@media (min-width: 1200px) {
		.page-title-wrapper {padding:300px 0 0; ;
			.page-title {
				h1, h2 {font-size:54px; margin: -30px 0 0;}
			}
		}
		ol.breadcrumb { padding:30px 0 20px;
			li {
				a {font-size:16px;}
			}
		}
}

