ul.list-group {
	li.list-group-item {
		.list-group-item-heading {font-size:20px; margin-bottom:10px;
			small {font-size:80%; color:$secondary;}
		}
		h3 {margin:0;}
		p {margin:0;}
	}
	a.list-group-item {
		h4 {color:$blue;}
	}
}
