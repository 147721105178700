.live-event {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	// min-height: 550px;
	.live-event-stream {
		position: relative;
		flex: 1 1;
		min-width: 400px;
		.responsive-16by9 {
			max-height: 90vh;
		}
	}
	.live-event-chat {
		position: relative;
		flex: 0 0;
		width: 350px;
		min-width: 350px;
		max-width: 350px;
		min-height: 400px;
		iframe {
			position: relative;
			width: 100%;
			height: 100%;
		}
	}
	figure {
		position: relative;
		max-width: 100%;
		max-height: 100%;
	}
}

.responsive-16by9 {
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
	box-sizing: border-box;
	&::before {
		display: block;
		content: "";
		padding-top: 56.25%;
		box-sizing: border-box;
	}
	> iframe {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}
.main-content {
	figure {
		a, img {
			max-width: 100%;
		}
		a:hover {
			filter: saturate(200%) contrast(125%);
		}
	}
}
