.callout {background:$primary; background-image:url(/_system/stylesheets/webart/branding/danish-mark-red.svg); background-repeat:no-repeat; background-position:center center;
	.container {
		h4 {padding:60px 0; color:#FFF; text-align:center; @include primary-light; font-size:28px; color:rgba(#FFF, 0.5);
			span {color:#FFF; border-bottom:1px dotted rgba(#FFF, 0.25); @include primary;}
		}
	}
}
@media (min-width: 768px) {
	.callout {
		.container {
			h4 {padding:80px 0; font-size:32px;}
		}
	}
}
@media (min-width: 992px) {
	.callout { background-attachment:fixed; background-position:center top;
		.container {
			h4 {padding:100px 0; font-size:36px;}
		}
	}
}
@media (min-width: 1200px) {
	.callout { background-position:right top;
		.container {
			h4 { font-size:42px;}
		}
	}
}

footer {
	.footer { background-color:#333; padding:30px 0;}
	.address {background:#DDDDDD; color:$secondary; padding:20px 0 40px; box-shadow:0 0 16px 0 rgba(0,0,0,0.25);
		.col-md-8 {
			address {@include primary; font-size:16px; margin-bottom:0;
				strong {display:block; margin:20px 0 15px; font-size:26px; @include primary; font-weight:normal; color:$primary;}
				.media {margin-top:5px;}
				.media-left {padding-right:5px;
					i.icon {font-size:24px; color:$primary;}
				}
			}
		}
		h4 {
			font-size:26px;
			margin:40px 0 15px;
			padding:40px 0 0;
			@include primary;
			font-weight:normal;
			color:$primary;
			border-top:1px solid $grey;
		}
		form {
			margin: 0 1.25rem !important;
			padding: 0 !important;
			.row {
				&+.form-group { 
					margin: 0;
				}
			}
			.form-group {
				margin: 0 !important;
				padding: 0 !important;
				.form-control {
					margin: 0 !important;
					box-shadow: none !important;
					border: none !important;
					border-radius: 0 !important;
				}
			}
			// .helper_text {
			// 	display: none !important;
			// }
			// label {
			// 	display: none !important;
			// }
			// .indicates-required {
			// 	display: none !important;
			// }
		}
	}
	ul.quicklinks {list-style:none; padding:0; margin-bottom:30px;
		li {color:#FFF; margin-bottom:5px; @include primary-light;
				&.quicklinks-title {font-size:26px; margin-bottom:15px;
					a {color:$primary;}
				}
			a {color:$grey;
				&:hover {color:$blue; text-decoration:none;}
			}
		}
		&+ul.quicklinks {margin-top:20px; margin-bottom:0; @include primary-light; font-size:14px;
			li {
				&.quicklinks-title {margin-bottom:5px;}
			}
			&+ul.quicklinks {margin-top:0; margin-bottom:0;}
		}
	}
	.copyright { margin-top:40px; text-align:center; color:#999; @include primary-light;
		a {color:#999; text-decoration:none;
			&:hover, &:active {color:$blue;}
		}
		.copyright-logo { 
			img {display:block; margin:20px auto; width:200px; height:auto;}
		}
		.copyright-refer {display:block; margin:10px auto 0; font-size:12px; color:#666;}
	}
	ul.links {list-style:none;margin:0; padding:0; font-size:14px; @include primary-light;
		li {display:inline-block;
			&:before {content:" | "; color:rgba(#FFF, 0.25)}
			&:first-child:before {content:""}
			a {}
		}
	}
	ul.social {list-style:none; margin:20px -15px; padding:0;
		li {margin:0; padding:0; display:block; transition:all 0.3s ease; width:20%; float:left;
				&:hover, &:active {
					a {background:$blue; 
						i {color:#FFF;}
					}
				}
				&:first-child {
					a {border:none;}
				}
			a {display:block; background:$primary; text-align:center; transition:all 0.3s ease; border-left:1px solid $darkprimary;
				i {font-size:22px;  line-height:50px; color:#FFF;}
			}
		}
	}
}
@media (min-width: 768px) {
	footer {
		.footer {padding:60px 0 60px;}
		.copyright {border-top:1px solid #999; 
			.copyright-logo { 
				img {display:block; width:260px;}
			}
		}
		ul.quicklinks {padding-left:40px;}
		ul.social {margin:-20px 0 40px;
			li {display:inline-block; float:none; width:inherit; border:1px solid #999;
				&:hover, &:active {border-color:transparent;
					a {background:$blue;}
				}
				a { height:38px; width:38px; background:#333; border:none;
					i {line-height:38px; color:$grey;}
				}
			}
		}
	}
}
@media (min-width: 992px) {
	footer {
		.footer {padding:60px 0 80px;  background-image:url(/_system/stylesheets/webart/branding/danish-mark.svg); background-repeat:no-repeat; background-attachment:fixed; background-position:center top;}
		.address {padding:40px 0 ; 
			.col-md-8 {
				address {
					strong {margin:0 0 15px;}
				}
			}
			form { margin-left:15px;}
			h4 {margin:0 0 15px 15px; border:none; padding:0;}
			.col-md-4 {border-left:1px solid $grey;}
		}
		.copyright {margin-top:60px;
			.copyright-logo { 
				img {margin-top:60px; width:320px;}
			}
		}
		ul.links {display:inline;
			li {display:inline-block;
				&:first-child:before {content:" | "}
			}
		}
	}
}
@media (min-width: 1200px) {
	footer {
		.footer {background-position:right top;}
	}
}