.exhibits-grid-grey{background:$grey;padding:40px 0;}
.exhibits-grid {text-align:center;
	h2 {margin:0 0 10px; padding:0; font-size:32px; text-align:left;}
	.col-sm-6:nth-child(n+5){display:none;}
	.exhibits-grid-item {display:block; position:relative; background:#000; height:120px; margin-bottom:1px; @include transition(all 0.3s ease-in-out);
		.exhibits-grid-bkg {position:absolute; z-index:0; height:100%; width:100%;background-repeat:no-repeat; background-size:cover; background-position:center center; @include filter(grayscale(100%));}
		.exhibits-grid-callout {position:relative; z-index:10; height:100%; @include transition(all 0.3s ease-in-out); background:rgba($primary, 0.85);
			h5 {margin:20px; padding:0; text-align:left; border-left:4px solid #FFF; padding:0 0 0 10px; color:#FFF; font-size:18px;}
			h6 {text-align:left; margin:0 20px 0; color:$darksecondary;}
		}
		.grid-icon{position:absolute;top:10px;left:20px;color:#fff;font-size:30px;opacity:0;@include transition(all 0.3s ease-in-out);z-index:10;
			span{position:absolute;top:28%;left:0;width:100%;text-align:center;font-size:16px;color:#000;}
		}
	}
	a.btn-primary{margin-top:20px;}
}

@media (min-width: 768px) {
	.exhibits-grid-grey{padding:60px 0;}
	.exhibits-grid {
		h2 {font-size:40px; margin:0 0 20px;}
		.btn {margin:0; display:inline-block;}
		.exhibits-grid-item {height:345px; margin-bottom:30px;
			&:hover {
				.exhibits-grid-bkg {@include filter(grayscale(100%));}
				.exhibits-grid-callout {background:rgba($primary, 0.85); display:block; border:1px solid #FFF;
					&:after {content:'\f013'; display:inline-block; @include animated; @include animation-name(fadeIn);}
					h5 {}
				}
				.grid-icon{opacity:1;}
			}
			.exhibits-grid-bkg {@include filter(grayscale(0%));}
			.exhibits-grid-callout {display:none; background:transparent;
				&:after {content:'\f013'; position:absolute; top:15px; right:15px; @include icon; z-index:100; color:#FFF; font-size:28px;}
				h5 {text-align:right; border-left:none; border-right:4px solid #FFF; padding:0 10px 0 0; overflow:hidden;
					span {position:relative; display:inline-block; @include animated; @include animation-name(slideInRight)}
				}
				h6 {text-align:right;}
			}
		}
	}
}
@media (min-width: 992px) {
	.exhibits-grid-grey{padding:80px 0;}
	.exhibits-grid {
		h2 {font-size:44px;}
		.col-sm-6:nth-child(n+5){display:block;}
		.exhibits-grid-item {height:212.5px;}
	}
}
@media (min-width: 1200px) {
	.exhibits-grid {
		.exhibits-grid-item {height:262.5px;}
	}
}

.exhibit-list{
	.filter-button-group{margin-bottom:25px; border-bottom:1px solid $lightgrey; padding-bottom:25px;
		.btn-group {margin:0 15px 0 0;
			button {
				&.btn-info {
					&.selected{background:$blue; color:#FFF;}
				}
			}
		}
	}
	a.element-thumbnail{position:relative; display:block; margin-bottom:10px; background-color:#fff; @include transition(all 0.3s ease-in-out); overflow:hidden;
		&:hover, &:active {text-decoration:none;}
		&.noimg {background:$primary; padding:20px; border:1px solid $primary;}
		&.hasimg {
			.caption-container {position:absolute; width:100%; bottom:0; padding:10px 10px; background:rgba(#000, 0.65); @include transition(all 0.3s ease-in-out);
				.caption {
					h4 {}
				}
			}
		}
		.caption-container {height:auto; @include transition(all 0.3s ease-in-out); overflow:hidden; text-overflow: ellipsis;
			.caption { position:relative; border-right:4px solid #FFF;
				h4 {text-align:right; color:#FFF; margin:0 10px 5px; @include primary;}
				.label {float:right; margin-right:10px;}
			}
		}
		img {width:100%;}
	}
}

@media (min-width: 768px) {
	.exhibit-list{
		.el-grid{ margin-left:10px;margin-right:10px;
			.col-sm-3{padding-right:5px;padding-left:5px;}
		}
		a.element-thumbnail{
			&.noimg {
				&:hover{background:none;
					.caption-container {height:100%;
						.caption {border-color:$primary;
							h4 {color:$primary;}
						}
					}
				}
			}
			&.hasimg {
				&:hover {
					img { @include filter(grayscale(100%));}
					.caption-container {height:100%; max-height:100%; background:rgba($primary, 0.85);
						.caption{
							h4 {}
						}
					}
				}
			}
		}
	}
}
