.home-grid-2 {
	.whiteout-bottom::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		height: 50%;
		background-color: #fff;
	}
	> .row {@include nopadding;overflow:hidden;
		> .col-sm-7, >.col-sm-5 {@include nopadding; overflow:visible;}
	}
	.angle {position:relative; left:-60%; height: 275px; width:300%; background-color:rgba(255,255,255,0.3); @include transform(rotate(35deg) scale(1) translate(0px));
		&.angle-top {margin-top: -100px;height: 260px;margin-bottom: 59px;background-position: center; background-size: contain;background-image:url(/_system/stylesheets/webart/background/glass.jpg);}
		&.angle-middle {margin-bottom:61px; z-index: 100;}
		&.angle-bottom {margin-bottom:100px;background-image:url(/_system/stylesheets/webart/background/knife.jpg);}
		&.angle-member {
			height: 75px; background: rgba(255,255,255,0.7); z-index: 300; margin-top: -12px;
			.screen{
				position: absolute;top: 0;left:0;width: 40%;height: 100%;background-color: #fff;
			}
		}
		.angle-text {position:relative; left: -190px; bottom: -20px; width:300px; margin:0 auto; text-align:left; @include transform(rotate(-35deg) scale(1) translate(0px));
			h2 {color:#FFF; font-size:44px;}
			a.btn {float:right;}
		}
	}
	.angle-inner {
	    position: absolute; top: 260px; left: -90px; height: 420px; width: 210%; background-image:url(/_system/stylesheets/webart/background/water.jpg); background-repeat: no-repeat; background-size: cover; z-index: 0; @include transform(rotate(35deg) scale(1) translate(0px)); transform-origin: top; -ms-transform-origin: top;
	}
	.column {position:relative; background:#000; background-repeat:no-repeat; background-size:cover; background-position:center center;
		&.column-top {background-image:url(/_system/stylesheets/webart/background/building.jpg); height: 746px;}
		&.column-bottom {background-image:url(/_system/stylesheets/webart/background/statue.jpg); height: 855px; z-index: 150;}
	}
	.calendar-triangle{
		position: absolute; bottom: 0px; left: 15px; width: 100%; background-color:rgba(255,255,255,0.5); padding:20px 30px; z-index: 400;
		h2{font-size:40px;margin-top:0;}
		a{font-size:22px;}
	}
	.museum-hours{
		position: absolute; top: 36%; left: 15px; z-index:170; width: 100%; background-color:rgba(255,255,255,0.7); padding:20px 30px; height: 170px;
		h2{font-size: 40px;margin-top:0;}
		a{font-size:22px;}
	}
	.benefits-container{
		position: absolute; top: 810px; left:10%; width:96%; z-index:340;
		h2{
			font-size:44px;
		}
	}
	.benefits-container-copy {
		position: relative; margin-top: -281px; margin-left:8%; margin-bottom: 30px; padding-right: 30px; z-index:340;
		clear: both;
		h2{
			@include primary-bold;
			font-size:44px;
		}
	}
	.genealogy-container{
		position: absolute; top: 20%; width: 100%;
		.genealogy-text{
			border: 3px solid #fff; text-align: center; padding: 20px 0; margin: 0 80px;
			h2{margin-top:0;color:#fff;}
		}
	}
}

@media (min-width: 768px) {
	.home-grid-2{
		.col-sm-6{padding-right:0;}
		.angle{
			height: 320px;
			&.angle-top { margin-top: 0;height: 327px;margin-bottom: 71px; }
			&.angle-middle { margin-bottom:70px; }
			&.angle-bottom { margin-bottom: 91px; }
			&.angle-member { height: 400px; }
			.angle-text {
			    left: -217px;bottom: -71px;
			    h2{
					font-size:60px;
				}
			}
		}
		.angle-inner {
		    top: 267px;height: 405px; width: 200%;
		}
		.benefits-container{
		    top: 925px;width:96%;left:15px;
		    a.btn{float:right;}
		    .benefits-triangle{
				background-image:url(/_system/stylesheets/webart/background/bkg-collections.png);background-repeat:no-repeat;background-size:cover;width:360px;height:240px;z-index: 400;
			}
		}
		.benefits-container-copy{
			margin-top: -425px; margin-left:0;
		    a.btn{float:right;}
		}
	}
}
@media (min-width: 992px) {
	.home-grid-2{
		.angle{
			height: 400px;
			&.angle-top {  margin-bottom: 80px; }
			&.angle-middle { margin-bottom:88px; }
			&.angle-member {  height: 295px; }
			&.angle-bottom { margin-bottom: 88px; }
			.angle-text { left: -430px;bottom: -105px; }
		}
		.angle-inner { top: 340px;height: 600px; }
		.column{
			&.column-bottom { height: 1000px; }
		}
		.benefits-container{ top:1066px;padding-left:30px; }
		.benefits-container-copy { margin-top: -360px; margin-left:30px; }
		.museum-hours{ top: 33%; }
	}
}
@media (min-width: 1200px) {
	.home-grid-2{
		.benefits-container { top: 988px;padding-left: 11%;
			.benefits-triangle{ width:580px;height:400px; }
		}
		.benefits-container-copy { margin-top: -280px; margin-left:11%; }
	}
}
@media (min-width: 1500px) {
	.home-grid-2{
		.benefits-container { top: 890px;
			.benefits-triangle{ width:684px; height:480px; }
		}
		.benefits-container-copy { margin-top: -295px; }
		.angle .angle-text {
		    left: -570px; bottom: -128px;
		}
	}
}














