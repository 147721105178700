@mixin primary {
	font-family:'Roboto';
	font-weight: 400;
}
@mixin primary-light {
	font-family:'Roboto';
	font-weight: 300;
}
@mixin primary-italic {
	font-family:'Roboto';
	font-weight: 400;
	font-style: italic;
}
@mixin primary-bold {
	font-family:'Roboto';
	font-weight: 700;
}
@mixin primary-black {
	font-family:'Roboto';
	font-weight: 900;
}
@mixin secondary {
	font-family:'Caladea';
}
@mixin secondary-italic {
	font-family:'Caladea';
	font-style: italic;
}
@mixin secondary-bold {
	font-family:'Caladea';
	font-weight: 700;
}

@mixin enable-gpu {
	@include transform(translateZ(0));
}
@mixin hide-text {
  font: 0/0 a; 
  text-shadow: none;
  color: transparent;
}

@mixin nopadding {
	padding:0 !important; 
	margin:0 !important;
}
@mixin icon {
	display: inline-block;
    font: normal normal normal 14px/1 'Moda';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0,0);
}

@mixin animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}