
$primary: #b94e5e;
$darkprimary: #851E33;
$secondary: #726C67;
$darksecondary: #3B3835;

$tan: rgb(197,171,133);
$grey: rgb(197,198,196);
$darkgrey: rgb(51,51,51);
$lightgrey: rgb(228, 228, 228);
$blue: rgb(62,184,229);
$darkblue: rgb(0, 116, 153);
$green: rgb(151,168,37);
$yellow: rgb(237,183,43);
$purple: rgb(130,86,127);

h1 {@include secondary; color:$primary; font-size:32px;}
h2 {@include secondary; color:$secondary; font-size:32px;
	span, strong {@include primary-bold;}
}
h3 {@include secondary; color:$primary; font-size:24px;
	i {color:$primary;}
}
h4 {@include primary; color:$darkgrey; font-size:20;
	span, strong {@include primary-bold;}
}
h5 {@include primary;}

p, ul, ol {@include primary; color:#666; font-size:16px;
	strong {@include primary-bold; color:#333;}
}
small { @include primary;}
address {@include primary; color:$darksecondary;
	strong {@include primary-bold;}
}
a {color:$blue;}

a,button,.btn,input,a:focus,button:focus,.btn:focus,input:focus{outline:none;}
