body.system {background:$lightgrey;
	.sys-message {margin-top:20%; background:#FFF; padding:60px 40px; border-radius:10px 10px 0 0; background-image:url(/_system/stylesheets/webart/branding/danish-mark-light-grey.svg); background-size:250px 250px; background-position:center center; background-repeat:no-repeat; 
		.sys-message-content {text-align:center;}
	}
	.sys-action {
		.col-sm-6 {
			&+.col-sm-6 {border-top:1px solid #FFF;
				.btn {border-radius:0 0 10px 0;}
			}
			.btn {border-radius:0 0 0 10px; }
		}
	}
	img {display:block; margin:30px auto 0; width:200px;}


	@media (min-width: 992px) {
		.sys-action {padding:0 15px;
			.col-sm-6 {margin:0; padding:0;
				&+.col-sm-6 {border-top:none; border-left:1px solid #FFF;
					.btn {border-radius:0 0 10px 0;}
				}
				.btn {border-radius:0 0 0 10px; }
			}
		}
	}
}