#search {display:none;}
@media (min-width: 768px) {
 #search {
  display:none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
   z-index:1040;
  &.open {
    display:block;
    opacity: 1;

  }
  input[type="search"] {
    position: absolute;
    top: 50%;
    width: 100%;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0);
    font-size: 60px;
    font-weight: 300;
    text-align: center;
    border: 0px;
    margin: 0px auto;
    margin-top: -51px;
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    @include animation-duration(0.5s);
  }
  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 61px;
    margin-left: -45px;
    @include animation-delay(0.5s);
  }
  .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    background-color: rgba(#000, 0.0);
    border-color: #357ebd;
    opacity: 1;
    padding: 10px 17px;
    font-size: 27px;
    text-shadow:none;
    &:active, &:hover {
      i {color:$blue;}
    }
  }
}
}
