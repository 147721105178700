.page-title-wrapper .page-title h1 {bottom:56px;}
@media (min-width: 768px) {
	.page-title-wrapper .page-title h1 {bottom:42px;}
};
@media (min-width: 1200px) {
	.page-title-wrapper .page-title h1 {bottom:56px;}
};


.productsearchPagination{
	display:none;
}
.search-pagi .btn{width:140px;}

.account-form{display:none;}

#catshopcontinue{
	text-decoration:none;border-radius: 0;background-color:#fff;@include transition(all 0.3s ease-in-out); border:1px solid $primary;color:$primary; padding: 8px 16px;font-size: 14px;line-height: 1.42857143;text-align: center;white-space: nowrap;vertical-align: middle;touch-action: manipulation;cursor: pointer;
	&:hover{background-color:$primary;color:$lightgrey;@include transition(all 0.3s ease-in-out);}
}

/************************ Store Landing Page *********************/
.store-home{
	background-color:#dddddd;
	.member-top{padding-top:195px;
		h3:nth-child(2){margin-top:100px;}
	}
	.member-login{padding-top:100px;}
	.catalog-top{padding-top:15px;
		a{display:block;}
	}
	a{
		position:relative;background-color:#fff;margin-bottom:10px;overflow:hidden;text-decoration:none;

		.screen{
			position:absolute;top:0;left:-100%;width:60%;height:600px;background-color:rgba(255,255,255,0.8);@include transition(all 0.3s ease-in-out);
			transform:rotate(-55deg);transform-origin:10% 26%;-webkit-transform:rotate(-55deg);-webkit-transform-origin:10% 26%;-ms-transform:rotate(-55deg);-ms-transform-origin:10% 26%;
		}
		&:hover .screen{left:0;}
		&:hover .bk1{background-size:110%;}
		&:hover h2{color:$primary;}

		h2{padding:15px;margin:0;position:relative;z-index:20;font-size:26px;}
		.bk1{height:80px;width:100%;background-size:100%;@include transition(all 0.3s ease-in-out);}
	}
	.bk2{height:650px;width:100%;background-color:#fff;display:block;padding:0 20px;position:relative;overflow:hidden;
		h3{margin-top:0;color:#fff;
		}
		.screen{
			position:absolute;top:900px;height:2000px;width:550px;background-color:rgba(255,255,255,0.8);transform:rotate(-55deg);transform-origin:10% 26%;@include transition(all 0.3s ease-in-out);
		}
		&:hover .screen{top:160px;}
		&:hover h3{color:$primary;}
	}
	.membership-co{background:transparent url(/_system/stylesheets/webart/background/building.jpg)center no-repeat;
		h2{color:$lightgrey;}
	}
	.kitchen-co{background:transparent url(/_system/stylesheets/webart/background/glass.jpg)center no-repeat; }

	.books-co{background:transparent url(/assets/store/books-media/2802-sweet-paper-crafts-25-simple-projects-to-brighten-your-life.jpg)center no-repeat; }
	.home-co{background:transparent url(/assets/store/decor/candles/3584-kahler-fiducia-candle-holder-and-vase-set.jpg)center no-repeat;}
	.fashion-co{background:transparent url(/assets/store/fashion/4035-subtle-stripes-color-fade-scarf-pink.jpg)center no-repeat;}
	.jewelry-co{background:transparent url(/assets/store/jewelry/bracelets/4015-anne-marie-chagnon-micheline-bracelet.jpg)center no-repeat;

	}
	.novelties-co{background:transparent url(/assets/store/novelties/3017-i-love-copenhagen-mug.jpg)center no-repeat;
		h2{color:$lightgrey;}
	}
	.kids-co{background:transparent url(/assets/store/kids/3970-maileg-bunny-green-dish-set.jpg)center no-repeat;

	}
	.store-login-signup{
		min-height:500px;background-color:#fff;margin-bottom:20px;
	}
}
@media (min-width: 768px) {
	.store-home{
		.catalog-top{padding-top:160px;padding-bottom:20px; }
	}
}
@media (min-width:  992px) {
	.store-home{
		.catalog-top{padding-top:200px;}
		a{
			h2{padding:20px;margin:0;}
			.bk1{height:100px;}
		}
	}
}
@media (min-width:  1200px) {
	.store-home{
		.catalog-top{padding-top:220px;}
	}
}


/*********************** Featured  & Related **********************/
.featured-container,.related-container{
	background-color:#fff;padding:30px 0 60px;text-align:center;
	h2{margin-bottom:30px;}
	a{display:block;
		img{margin:0 auto 15px;}
	}
	a.item-name{color:$primary;}
	.product-pricing{
		h4{margin:4px 0 0;
			&.price-label{font-size:14px;}
		}
	}
}

/*************************** System Msg **********************/
div#messageBox {
		 position: fixed;
		 bottom: 12px;
		 right: 12px;
		 font-family: Arial, Helvetica, Sans-Serif;
		 font-size: 12px;
		 background-color: #000000;
		 color: #FFFFFF;
		 padding: 6px;
		 display: none;
}
.alert{
	margin-bottom:0;border-radius:0;text-align:center;
	h4{margin:0;}
	.close{line-height:.8;}
}

.customer-invoice{
	margin-top:50px;margin-bottom:100px;
}




.shop-main{
	margin:0;padding:0;

	.pagination-container{
		a.btn{width:140px;}
		.pagination-pages{
			font-size:20px;text-align:center;color:$primary;
			a{font-size:14px;}
		}
	}
	//product slide out sidebar
	button#searchMenu{
		float:left;
	}
	.product-filter-sidebar{

		h3.secondary-title{
			padding: 10px; border-top: 4px solid #fff;position: relative;display: block;background: $primary;padding: 15px;font-family: 'Roboto';font-weight:300;color: #FFF;margin: 0;font-size: 16px;
			span{font-family: 'Roboto'; font-weight: 700;}
		}
		.lower-sidenav{
			padding:8px;
		}

		.product-filter-container{
			background-color:#fff;padding:0;
			h4{
				color:$primary;font-size:22px;
			}
		}
	}
}
@media (min-width: 768px) {
	.shop-main{
		button#searchMenu{
			margin:12px 0;
		}
		.product-filter-sidebar{
			.product-filter-container{ padding:30px 0 0;}
		}
	}
}
@media (min-width: 992px) {
	.shop-main{
		padding:40px 0;
		.product-filter-sidebar{
			padding:10px;
			.product-filter-container{
				width:auto;border:none;padding:0;
			}
		}
	}
}

.grid-list-container{
	margin-top:15px;
	button{border:none;background-color:transparent;font-size:30px;vertical-align: bottom;color:$lightgrey;}
}
@media (min-width: 1200px) {
	.grid-list-container{
		margin-top:25px;
	}
}

.product-layout,.store-search-results{
	padding-top:40px;
	.product-sm-container{
		text-align:center;
		&.grid-box{
			height:265px;
			.thumbnail{
				width:100%;display: inline-block;border-radius:0;border:none;margin-bottom:0;overflow:hidden;
				table{
					height:150px;width:100%;
					img{margin:0 auto;display:block;}
				}
			}
			.product-details{
				text-align:center;
				.product-description{display:none;}
			}
		}
		&.list-box{
			min-height:105px;margin-bottom:10px;
			.product-image{
				float:left;margin-right: 10px;
				.thumbnail{
					max-width:100px;display: inline-block;border-radius:0;border:none;margin-bottom:0;
					table{
						height:92px;width:100%;
						img{margin:0 auto;display:block;width:92px;}
					}
				}
			}
			.product-details{
				float:left;width:70%;
				.product-description{display:inline-block;}
			}
		}
		.product-details{
			display: block; vertical-align: top;
			.product-name{
				a{font-weight:600;color:$primary;}
			}
			.product-pricing{
				h4{margin:4px 0 0;
					&.price-label{font-size:14px;}
				}
				li{padding-left:0;}
			}
		}
	}
	#product-large-image{
		margin:0 auto 20px;max-height:400px;
	}
	h4.sale-price{
		font-size:22px;
	}
	h4.member-price{
		font-size:24px;
	}
	.price-summary{
		h4,h4.member-price,h4.sale-price{
			text-align:right;
		}
	}
	.lrg-prod-description{
		h5{margin-bottom:0;}
	}
	.productSubmitInput{border-radius: 0;background-color:#fff;font-weight:600;@include transition(all 0.3s ease-in-out);border:4px solid $primary;display:block;width:100%;margin-top:20px;padding: 6px 12px;color:$primary;
		&:hover{background-color:$primary;color:$lightgrey;@include transition(all 0.3s ease-in-out);}
		.icon{font-size:20px;}
	}
	.favoritelink{border-radius: 0;background-color:#fff;font-weight:600;@include transition(all 0.3s ease-in-out);border:2px solid $primary;display:block;width:100%;margin-top:16px;margin-bottom:6px;padding: 6px 12px;color:$primary;text-align:center;text-decoration:none;
		&:hover{background-color:$primary;color:$lightgrey;@include transition(all 0.3s ease-in-out);}
	}
}
@media (min-width: 768px) {
	.product-layout,.store-search-results{
		padding-top:0px;
		.product-sm-container{
			text-align:left;
			&.grid-box{
				height:300px;
				.thumbnail{
					height:160px;
					table{
						height:152px;
					}
				}
			}
		}
	}
}
@media (min-width:  992px) {
	.product-layout,.store-search-results{
		.product-sm-container{
			&.list-box{
				.product-details{
					width:80%;
				}
			}
		}
	}
}




.cartLink{
	text-align: center; display: block; font-size: 24px;text-decoration:none;
	&:hover{color:$primary;}
}
p.alertMessageHolder_success{display:none;}
p.alertMessageHolder_error{
	text-align:center;font-size:20px;color:$primary;font-weight:600;margin-top:16px;
}
.empty-car-msg{
	text-align:center;
	a{font-size:28px;
		&:hover{color:$primary;}
	}
}
.shop-cart{
	padding-top:30px;padding-bottom:30px;
	h2{position:relative;z-index:1;}
	.save-header{
		text-align:center;padding:3px 0 5px;border:2px solid $primary;
		a{text-decoration:none;color:$primary;}
	}

	.cart{
		background-color:$lightgrey;padding:24px;
		.cart-product{
			background-color:#fff;margin-bottom:4px;padding:6px;
			.remove-item{
				a{font-size:24px;}
				table{text-align:center;}
			}
			.item-count{
				input{width:50px;text-align:center;margin:auto;}
			}
			img{
				margin:5px auto;
			}
			table{
				width:100%;margin:0;
			}
		}
		.shippingDiv{
			margin-bottom:16px;
			select.shippingDropDown{
				width:100%;display: block; height: 34px;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;color: #555;background-color: #fff;background-image: none;border: 1px solid #cdcdcd;border-radius: 0;-webkit-box-shadow: none;box-shadow: none;-webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;-o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
			}
		}

		.continue-btn{margin-top:40px;}
		#shippingSpan{display:none;}
		.checkout-container{
			background-color:#fff;margin-bottom:10px;padding:6px;
			input#DiscountCode, input#GiftVoucher{width:100%;}
			h4{color:$primary;}
			p{text-align:center;}
			.guest-checkout-btn{
				text-align:center;padding:10px 0 5px;
				#catshopbuy{text-align:center;color:$primary;margin:6px 0;padding:6px 0 12px;}
			}
			.logged-in{
				padding-bottom: 6px;
				#catshopbuy{
					text-decoration:none;border-radius: 0;background-color:#fff;font-weight:600;@include transition(all 0.3s ease-in-out); border:1px solid $primary;color:$primary;display:block;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;text-align: center;white-space: nowrap;vertical-align: middle;touch-action: manipulation;cursor: pointer;
					&:hover{background-color:$primary;color:$lightgrey;@include transition(all 0.3s ease-in-out);}
				}
			}
			.btm-border-gray,.btm-border{
				padding:6px 0;
			}
			.cart-total-price{height:46px;padding:10px 0;
				h3{margin-top:0;}
			}
			.select-ship-msg{display:none;}
		}
		.logged-in{
			padding-bottom: 6px;
		}
	}
}
#catshopbuy{
	text-decoration:none;border-radius: 0;background-color:#fff;font-weight:600;@include transition(all 0.3s ease-in-out); border:1px solid $primary;color:$primary;display:block;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;text-align: center;white-space: nowrap;vertical-align: middle;touch-action: manipulation;cursor: pointer;
	&:hover{background-color:$primary;color:$lightgrey;@include transition(all 0.3s ease-in-out);}
}
.guest-btn-title{padding-top:4px;}

.check-out-page{
	margin-top:30px;margin-bottom:20px;
	.site-secure{margin:10px 20px;vertical-align: baseline;}
	.btn{margin:20px 0;}
}
#catCartDetails{
	.modal{
		.modal-content{
			border-radius:0;
			.modal-header{
				height: 170px; background-image: url(/_system/stylesheets/webart/branding/danish-mark-red.svg); background-size: 150px 150px; background-position: center center; background-repeat: no-repeat; border: none; text-align: center;
				.close{
					font-size: 36px; top: 10px; right: 15px; float: none; position: absolute;
				}
			}
			.modal-body{
				h2{margin-top:0;}padding: 0 40px 40px;
			}
		}
	}
}



.catalog-name-container{
	h2{margin-top:10px;}
}

@media (min-width: 768px) {

	.shop-cart{

		h2{position:relative;z-index:1;}

		.cart{

			.cart-product{
				min-height:76px;background-color:#fff;margin-bottom:4px;padding:0px;
				.remove-item{
					a{font-size:24px;}
					table{text-align:center;}
				}
				.item-count{
					input{width:50px;text-align:center;margin:auto;}
				}
				img{
					margin:5px auto;
				}
				table{
					height:76px;width:100%;
				}
			}
			.shippingDiv{
				margin-bottom:0;
				select.shippingDropDown{
					display: block; height: 34px;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;color: #555;background-color: #fff;background-image: none;border: 1px solid #cdcdcd;border-radius: 0;-webkit-box-shadow: none;box-shadow: none;-webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;-o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
				}
			}


			#shippingSpan{display:none;}
			.checkout-container{
				background-color:#fff;margin-bottom:10px;padding:2px 6px 8px;margin-top:35px;
				h4{color:$primary;}
				p{text-align:center;}
				.select-ship-msg{text-align:center;}
				.logged-in{
					padding-bottom: 6px;
					#catshopbuy{
						text-decoration:none;border-radius: 0;background-color:#fff;font-weight:600; border:1px solid $primary;color:$primary;display:block;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;text-align: center;white-space: nowrap;vertical-align: middle;touch-action: manipulation;cursor: pointer;
						&:hover{background-color:$primary;color:$lightgrey;}
					}
				}
			}
		}
	}
}

.store-product-thumbnail {
	width: 150px; 
	height: 150px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.paypal-button-container {
	margin: 2.5rem 0 1.25rem 0;
	padding: 1.25rem;
	background-color: #f8f8f8;
	border-radius: 0.75rem;
	border: 1px solid #eee;
}

.alert-success {
	margin: 2.5rem 0 2.5rem 0;
}
