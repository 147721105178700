// input[type="text"],
// input[type="date"],
// input[type="datetime"],
// input[type="email"],
// input[type="number"],
// input[type="search"],
// input[type="time"],
// input[type="url"],
// input[type="password"],
// textarea,
// select
// {
//     box-sizing: border-box;
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     outline: none;
//     display: block;
//     width: 100%;
//     padding: 7px;
//     border: none;
//     border-bottom: 1px solid #ddd;
//     background: transparent;
//     margin-bottom: 10px;
//     font: 16px Arial, Helvetica, sans-serif;
//     height: 45px;
// }
// input[type="button"],
// input[type="submit"]{
//     box-shadow: inset 0px 1px 0px 0px #45D6D6;
//     background-color: #2CBBBB;
//     border: 1px solid #27A0A0;
//     color: #FFFFFF;
//     display: inline-block;
//     cursor: pointer;
//     font-size: 14px;
//     padding: 8px 18px;
//     margin:20px 0;
//     text-decoration: none;
//     text-transform: uppercase;
// }
// input[type="button"]:hover,
// input[type="submit"]:hover {
//     background:linear-gradient(to bottom, #34CACA 5%, #30C9C9 100%);
//     background-color:#34CACA;
// }
label {
    input[type="checkbox"],
    input[type="radio"] {
        margin-right: 4px !important;
    }
}

.form-check {
    &.field_type-radio_group {
        label:not(.form-check-label) {
            display: block;
        }
    }
}

input[type="email"] {
    -webkit-user-select: text; /* Safari */
    -ms-user-select: text; /* IE 10+ */
    user-select: text;
    // background:#77aaaa;
}

input[autocomplete="off"]::-webkit-contacts-auto-fill-button,
input[autocomplete="off"]::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
}
