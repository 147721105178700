.panel {
	&.panel-default {
		.panel-heading {background:$secondary;}
	}
	&.panel-primary {border-color:#ddd;
		.panel-heading {background:$primary; border-color:#ddd;
		a{color:#fff;}
		}
	}
	.panel-heading { border-radius:0;
		h2 {@include primary-light; margin:0px; padding:0; color:#FFF; font-size:22px;text-transform:lowercase}
		h3 {margin:0; padding:0; color:#FFF;}
		small{color:#fff;}
	}
	.panel-body { border-top:none;
		h2, h3 {margin:0 0 10px; padding:0;}
		h3 {margin:0;
			&+p {margin-top:15px !important;}
			a{font-size:60%;}
		}
		h4 {}
		p {
			&:last-of-type {margin:0;}
			&+a {margin-top:15px;}
		}
	}
	hr{
		border-top:1px solid #ddd;margin:10px 0;
	}
}

.panel-group .panel{
	border-radius:0;
}