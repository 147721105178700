.feature-grid {
	.container-fluid {@include nopadding;
		.row {@include nopadding;}
		.nopadding {@include nopadding;}
	}
	img {background:#000; width:100%; height:auto;}
	.feature-grid-item {padding:30px 0;
		h2 {margin:0; padding:0;}
		h3 {margin:0; padding:0;}
		.btn {display:block; text-align:center; margin-top:15px;}
	}
}

@media (min-width: 768px) {
	.feature-grid {
		.feature-grid-item {padding:30px 30px 30px 0;
			.btn {display:inline-block;}
		}
	}
}
@media (min-width: 992px) {
	
}
@media (min-width: 1200px) {
	
}
