.modal-backdrop {
	&.in {opacity:1; background-color: rgba(0, 0, 0, 0.85);}
}
.modal {text-align:center; 
	.modal-content {text-align:left; border-radius:10px; @include animation-duration(0.5s);
		button.close {font-size:36px; top:5px; right:5px; float:none; position:absolute;}
		.modal-header { padding:60px 0; background-image:url(/_system/stylesheets/webart/branding/danish-mark-light-grey.svg); background-size:150px 150px; background-position:center center; background-repeat:no-repeat; border:none; text-align:center;
			h2 {color:$primary; text-transform:lowercase; font-size:32px;}
			img {width:50%; height:auto; margin:0 auto; display:block;}
		}
		.modal-body {padding:40px;}
	}
	.modal-dialog-footer { margin-top:30px; @include animation-delay(0.5s);
		a {@include primary-light; font-size:18px;}
	}
}
@media (min-width:768px){
	.modal-sm {width:410px;}
}