.item-pagers {position:relative; 
	.col-sm-6 {height:100%; position:relative;
		&+.col-sm-6 {
			a.item-pager {text-align:right;
				.table {
					.item-pager-overlay {border-left:none; border-right:4px solid #FFF;}
				}
			}
		}

		a.item-pager {position:relative; display:block; height:100px; text-align:left; color:#FFF;
			&:hover {
				.table {background:rgba($primary, 0.85);}
			}
			.item-pager-bkg {z-index:0; position:absolute; height:100%; width:100%; background-size:cover; background-position:center center; background-repeat:no-repeat; @include filter(grayscale(100%));}
			.table {background:rgba($darkgrey, 0.75); z-index:1; position:relative; @include transition(all 0.3s ease-in-out);
				.item-pager-overlay { margin: 0 20px; padding:0 10px; border-left:4px solid #FFF;
					strong {display:block; @include secondary; font-size:24px;}
				}
			}
		}
	}
}