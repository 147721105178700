.video-list{
	.element-item{width:100%;}
}
@media (min-width: 768px) {
	.video-list{
	    .element-item{width: 33.33333333%;}
	    .exhibits-grid .exhibits-grid-item{height:240px}
	}
}
@media (min-width: 996px) {
	.video-list{
    	.exhibits-grid .exhibits-grid-item{height:345px}
    }
}
.video-gallery{
	margin:20px 0;
	.thumbnail{
	    border:none;padding:0;margin:0;border-radius:0;position:relative;
	    .icon{position:absolute;top:30px;font-size:40px;display: block;width: 100%;text-align: center;}
	    img{opacity:0.6}
	    &:hover img{opacity:1;}
	}
	.vid-num{text-align:center;}
	h3.slick-arrow{
	    display: inline;position: absolute;top: 10px;font-size:20px;margin:0;
	}
	h3.slick-arrow:first-child{
	    left:10px;
	}
	h3.slick-arrow:last-child{
	    right:10px;
	}
	.list-inline{
		>li{width:30%;}
	}
}
@media (min-width: 768px) {
	.video-gallery{
		.thumbnail{
		    .icon{top:16px;font-size:40px;}
		}
		h3.slick-arrow{
		    top: 16px;font-size:40px;
		}
	}
}
@media (min-width: 992px) {
	.video-gallery{
		.thumbnail{
		    .icon{top:30px;}
		}
		h3.slick-arrow{
		    top: 30px;
		}
	}
}

#hero{
	.hero-img{position:relative;
		img{width:100%;}
	}
	.img-caption{
		position: absolute; bottom: 0; background-color: rgba(0,0,0,0.6);width: 100%;padding: 10px;text-align: center;color:#fff;
	}
}

#gallery{
	margin-top:20px;
	.thumbnail{
		position:relative;
		.icon{
			position:absolute;bottom:6px;left:6px;color:#000;font-size:20px;
		}
		img{
			opacity:0.5;
		}
		&:hover img{
			opacity:1;
		}
	}
}

.log-in-container{
	padding:16px;border:1px solid #ddd;
}
.log-in-header{
	margin-bottom: 14px;padding: 60px 0; background-image: url(/_system/stylesheets/webart/branding/danish-mark-light-grey.svg); background-size: 150px 150px;background-position: center center;background-repeat: no-repeat;border: none;text-align: center;
	h2{
		color: $primary; text-transform: lowercase; font-size: 32px;
	}
}

.landing-nav{
	ul{padding:0;
		li{list-style-type:none;}
	}
}

.moda-staff{
	.exhibits-grid {
		.exhibits-grid-item {
			height:165px;
			.exhibits-grid-callout {
				border:none;
				&:after {content:' ';}
			}
			&:hover {
				.exhibits-grid-callout {
					border:none;
					&:after {content:' ';}
				}
			}
		}
		a{color:#fff;}
		h5 small{color:#fff;}
	}
}
@media (min-width: 768px) {
	.moda-staff{
		.exhibits-grid {
			.exhibits-grid-item {height:375px;}
		}
	}
}
@media (min-width: 992px) {
	.moda-staff{
		.exhibits-grid {
			.exhibits-grid-item {height:300px;}
		}
	}
}
@media (min-width: 1200px) {
	.moda-staff{
		.exhibits-grid {
			.exhibits-grid-item {height:380px;}
		}
	}
}

.year-form,.matching-employer{display:none;}
.form-steps{
	h3{font-size:36px;}
	hr{margin-top:30px; border-top: 3px solid #b94e5e; float: left;width: 40px;opacity: 0.3;}
}
.step-box{
	float:left;width: 60px; text-align: center; border-radius: 10px; border: 1px solid #c5c6c4; overflow: hidden;
}
.step{
	background: #b94e5e; color: #FFF; padding: 2px 0;
}
.step-num{
	color: #b94e5e; padding: 4px 0; font-size: 24px; line-height: 1; font-family: 'Roboto';
}
.step-two{opacity:0.3;}
.step-three{
	opacity:0.3;float:left;
	h3{font-size:50px;margin-bottom:10px;margin-top: 8px;margin-left: -4px;}
}
#memForm button{margin-top:14px;}
.panel-video{margin-top:20px;}
.thumbnail-video{
	border:none;
}

.thumbnail>img{height:50px;}




