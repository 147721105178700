.navbar { z-index:200; margin:0; background:#FFF; border:none; border-bottom:1px solid #FFF; margin-bottom:-1px;
	.navbar-background {display:none;}
	.navbar-header {height:70px;}
	a.navbar-brand {text-indent:-9999em; background-image:url(/_system/stylesheets/webart/branding/museum-danish-america-logo-color.svg); background-repeat:no-repeat; width:60%; margin:10px 0; background-position:10% center; height:50px;}
	.navbar-toggle {border:none; width:36%; margin:0; margin-bottom:-5px; border-radius:0; padding:0; color:#FFF; border-bottom:5px solid $primary; transition:all 0.3s ease; z-index:100;
		&.collapsed {background:none; color:$primary; border:0px solid $primary;
			.navbar-toggle-lines {
				span {background:$secondary;}
			}
			.navbar-toggle-text {
				span {display:inline;
					&+span {display:none;}
				}
			}
		}
		.navbar-toggle-lines {float:right; height:70px; padding:30px 30px 30px 10px;
			span {width:30px; background:$secondary;}
		}
		.navbar-toggle-text {float:right; height:70px; line-height:70px; @include primary; color:$primary; text-transform:lowercase; font-size:18px;
			span {display:none;
				&+span {display:inline;}
			}
		}
	}
}
ul.navbar-utility {background:$primary; margin:0 -15px; line-height:40px; color:$darkprimary; text-transform:lowercase;
	> li {width:25%; text-align:center; float:left;
		&.active {position:relative;
			 a.btn  {color:rgba(255,255,255,0.85); padding:9px 0;
				i {color:$darkprimary;}
			 }
			ul.dropdown-menu {margin:-1px 0 0; border-radius:0; padding:0;
				li {
					&.dropdown-header {padding:2px 10px}
					&.divider {margin:0; background:$lightgrey;}
					a {padding:8px 15px; color:$darksecondary; @include primary;
						&:hover, &:focus, &:active {background:$lightgrey;
							i {color:$primary;}
						}
						span {@include primary-bold;}
						i {color:$grey;}
					}
				}
			}
		}
		> a {color:rgba(255,255,255,0.85); @include primary-light; font-size:14px;
			&:hover, &:active {color:#FFF; text-decoration:none;}
			i {font-size:14px;}
			.badge {background:#FFF; color:$primary;}
		}
	}
}
#main-nav {border:none;
	>ul {margin:0 -15px; background:#FFF; border-top:5px solid $grey;
		> li {  -webkit-animation-duration: 0.5s; animation-duration: 0.5s; -webkit-animation-fill-mode: both; animation-fill-mode: both; -webkit-animation-name: slideInLeft; animation-name: slideInLeft;
			&.active > a {background:$primary; color:#FFF;
				i {color:#FFF;}
			}
			&:first-child {border-top:1px solid #FFF;}
			&.open {
				> a {background:$primary; color:#FFF;
					i {
						&+i {display:none;
							&+i {display:inline-block; color:#FFF;}
						}
					}
				}
			}
			> a {@include primary-light; text-transform:lowercase; display:block; color:$primary; padding:20px 15px; font-size:24px;
					i {font-size:16px;
						&+i {color:$grey; font-size:24px; float:right;
							&+i {display:none;}
						}
					}
				}
			@for $i from 1 through 20 {
			    &:nth-child(#{$i}) {
			         -webkit-animation-delay: (0.1s * $i);
			         animation-delay: (0.1s * $i);
			    }
			}
			> ul { padding:0; border-bottom:5px solid $primary; border-radius:0;
				&:after {content:" "; position:absolute; bottom:0; right:0; height:0; width:0;  border-bottom: 50px solid $primary; border-left: 50px solid transparent;}
				> li {
					a {display:block; color:#666; @include primary; font-size:18px; margin:8px 0; padding:4px 0;  text-transform:lowercase;}
					> .row {margin:0;}
					ul {list-style:none; padding:0; margin:0 0 15px 0px; border-top:1px solid $grey;
						li {margin:0 0 0 10px; padding:0;
							a {color:$secondary; @include primary; font-size:14px;}
						}
					}
				}
			}
		}
	}
	.mobile-utility {margin:0 -15px; border-top:1px solid #FFF;
		> .btn-group {margin:0;
			> .btn-group {
				&:nth-child(1) { border-right:1px solid #FFF;}
				&:nth-child(2) { border-right:1px solid #FFF;}
				&:last-child {border:none;}
				.btn {border-radius:0; background:$secondary; color:#FFF; font-size:18px; @include primary-light; height:60px;  text-transform:lowercase; padding:0;
					small {display:block; font-size:14px; margin-top:2px;}
					i {color:$grey; display:block; width:100%;}
					&:focus {border:none; outline:none; box-shadow:0;}
					.badge {background:#FFF; color:$primary;}
				}
			}
		}
		.input-group {padding:0; overflow:hidden; border-radius:0;border-top:1px solid #FFF;
			.input-group-btn {
				.btn {height:50px; padding:0 20px; line-height:50px; background:$blue; border:none; color:#FFF;}
			}
			input {height:50px; border-radius:0; background:#FFF; color:$primary; border:none; }
		}
	}
}

@media (min-width: 768px) {
	.navbar {position:absolute; width:100%; background:none; border:none; margin-bottom:0;
		.navbar-background {display:block; background-image:url(/_system/stylesheets/webart/navigation/navigation-bkg.svg); background-repeat:no-repeat; background-size:cover; background-position:center top; position:absolute; height:170px; width:100%; max-width:1370px; margin:0 auto; top:0; left:0; right:0; z-index:-1; @include filter(drop-shadow(0px 0px 4px rgba(0,0,0,.4)))}
		a.navbar-brand {position:absolute; width:240px; height:65px; margin:0 0 0 -15px !important; background-image:url(/_system/stylesheets/webart/branding/museum-danish-america-logo.svg); background-position:center center;}
	}
	ul.navbar-utility {float:right; line-height:30px; margin:0 -15px; background:none;
		> li {width:auto; float:none;
			&.active {
				a.btn, a.login { border-right:1px solid rgba(255,255,255,0.2); margin-right:5px; padding-right:10px; color:#FFF; color:rgba(255,255,255,0.6);
					&.dropdown-toggle {color:rgba(255,255,255,0.8);}
					i {margin-right:4px;}
				}
				ul.dropdown-menu {right:10px;}
			}
			&:nth-child(6), &:nth-child(7), &:nth-child(8) {
				a {background:none;}
			}
			> a {color:rgba(255,255,255,0.6); font-size:14px; transition:all 0.4s ease;  background-image:url(/_system/stylesheets/webart/icons/arrow-down.png); background-repeat:no-repeat; background-position:center -20px; display:inline-block;
				&:hover, &:active {color:#FFF; text-decoration:none; background-position:center -3px;
					i {
						&.icon.flag {opacity:1;}
					}
				}
				i {font-size:14px;
					&.icon.flag {background:#FFF; color:red; opacity:0.5; transition:opacity 0.3s ease; border:2px solid #FFF;
						&.icon-us-flag {color:$blue;}
					}
				}
				.badge {background:#FFF; color:$primary;}
			}
		}
	}
	#main-nav { margin:0 20px 0 -15px;
		> ul {background:none;  padding:0; border:none; box-shadow:none;
			&:hover {
				> li {
					&.active > a {border:none; margin-top:0;}
					> a {color:rgba(255,255,255,0.5)}
				}
			}
			> li {border-top:1px solid rgba(255,255,255,0.2); animation:none !important; -webkit-animation:none !important;
				&.active, &.open {
					> a { border-top:1px solid #FFF; margin-top:-1px; background:none; }
				}
				&.open {
					> a {border-color:#FFF;}
				}
				&:first-child {border-top:1px solid rgba(255,255,255,0.2);}
				&.open {
					> a {
						i {
							&+i {display:none;
								&+i {display:none;}
							}
						}
					}
				}
				&:hover {
					&.active > a {border-top:1px solid #FFF; margin-top:-1px;}
					> a {color:#FFF; border-top:1px solid #FFF; margin-top:-1px; }
				}
				.megamenu {position:relative;
					.hidden-xs {@include nopadding;
						.megamenu-featured{padding-right:15px; margin:-20px 20px -40px -20px; padding:20px; background:$lightgrey; box-shadow:inset 0 0 8px 0 rgba(0,0,0,0.2); border-right:5px solid $secondary;
							&:after {content:" "; position:absolute; bottom:-40px; right:-20px; height:0; width:0;  border-top: 40px solid #FFF; border-right: 40px solid transparent;}
							a {position:relative; display:block; border:1px solid $grey; min-height:85px; background:#000; margin:0 0 8px; padding:0px; text-align:center; background-size:cover; background-repeat:no-repeat; color:#FFF; font-size:14px; @include primary-light;
								&:hover {
									.megamenu-featured-bkg {@include filter(grayscale(100%));}
									.megamenu-featured-overlay {height:100%; background:rgba($primary, 0.85); border-color:#FFF;}
								}
								&.exhibition {height:120px;}
								.megamenu-featured-bkg {z-index:0; position:absolute; top:0; left:0; right:0; bottom:0; height:100%; width:100%; background-size:cover; background-repeat:no-repeat;}
								.megamenu-featured-overlay{z-index:1; position: absolute; bottom:0; width:100%; height:40px; background:rgba(0,0,0,0.65); padding:0px 5px; text-align:right; border-right:5px solid $primary; @include transition(all 0.2s ease-in-out);; line-height:40px;
									span {@include primary-bold;}
								}
							}
							h4 {margin:0; padding:0; font-size:24px; position:absolute; @include transform(rotate(-90deg)); @include transform-origin(left top); right:-58px; top:61px; @include primary-light; text-transform:lowercase; color:$secondary;
								span {@include primary-bold;}
							}
							.exhibits-grid {margin:0; padding:0; background:none;
								.exhibits-grid-item {height:120px; padding:0;
									@for $i from 1 through 3 {
									    &:nth-child(#{$i}) {
									         -webkit-animation-delay: (0.1s * $i);
									         animation-delay: (0.1s * $i);
									    }
									}
									&:nth-child(3) {margin:0;}
									.exhibits-grid-bkg {@include filter(grayscale(0%));}
									.exhibits-grid-callout {
										&:after {content:""}
										h5 {font-size:14px;}
									}
								}
							}
							.col-sm-6 {padding-right:4px;
								&+.col-sm-6 {padding-right:15px; padding-left:4px;}
								a {margin:0;}
							}
						}
					}
				}
				> a {color:rgba(255,255,255,1); font-size:18px; transition:color 0.3s ease; background:none;
					&:hover {}
					i {padding-left:5px;
						&+i {display:none;}
					}
				}
				> ul  {-webkit-animation-duration: 0.25s; animation-duration: 0.25s;  -webkit-animation-fill-mode: both; animation-fill-mode: both; -webkit-animation-name: fadeInUp; animation-name: fadeInUp; border:none;
					&:after {content:""; display:none;}
					> .row {margin:0 -15px;}
					> li {animation:none !important; -webkit-animation:none !important;
						a {color:$primary; padding:0; margin:4px 0; }
					}
				}
			}
		}
		.megamenu {padding:20px;}
		// .dropdown:hover .dropdown-menu {
		//     display: block;
		//     margin-top: 0;
		//  }
	}
}
@media (min-width: 992px) {
	.navbar {
		.navbar-background {height:180px;}
		a.navbar-brand {width:280px; height:65px; margin:0 0 0 60px !important;}

	}
	ul.navbar-utility {
		> li {
			> a {font-size:14px; background-image:url(/_system/stylesheets/webart/icons/arrow-down.png); background-repeat:no-repeat; background-position:center -20px; display:inline-block;
				&:hover { background-position:center -3px;}
				i {font-size:16px}
			}
		}
	}
	#main-nav {
		> ul {
			> li {
				> a {font-size:20px; padding:20px 20px;}
				> ul {
					> li {
						a {padding: 2px 0;}
					}
				}
				.megamenu .hidden-xs .megamenu-featured{
					a{
						font-size:16px;
						.megamenu-featured-overlay{padding:0px 10px;}
					}
				}
			}
		}
	}
}
@media (min-width: 1200px) {
	.navbar {
		.navbar-background { height:190px;}
		a.navbar-brand {width:300px; height:80px; margin:-10px 0 0 115px !important;}
	}
	ul.navbar-utility {line-height:40px;
		> li {
			> a {
				&:hover { background-position:center 0px;}
				i {font-size:18px;}
			}
		}
	}
	#main-nav {
		> ul {
			> li {
				> a {font-size:24px; padding:25px 30px;}
				> ul {
					> li {
						a {padding: 2px 0;}
					}
				}
				.megamenu .hidden-xs .megamenu-featured{ padding:30px;
					a{ font-size:18px; }
				}
			}
		}
	}
}

.mega .nav,
.mega .collapse,
.mega .dropup,
.mega .dropdown {
  position: static;
}
.mega .container {
  position: relative;
}
.mega .dropdown-menu {
  left: auto;
}
.mega .mega-content {
  padding: 20px 30px;
}
.mega .dropdown.mega-fw .dropdown-menu {
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
	.mega .dropdown.mega-fw .dropdown-menu {
  		left: 0px;
	}
}
@media (min-width: 992px) {
	.mega .dropdown.mega-fw .dropdown-menu {
  		left: 100px;
	}
}
@media (min-width: 1200px) {
	.mega .dropdown.mega-fw .dropdown-menu {
  		left: 145px;
	}
}
