.sub-nav {
	ul {list-style:none; margin:0; padding:0; display:none;
		&.level {display:block;}
		li {margin:0; padding:0; float:left;
			&.active {
				a {color:$blue;}
			}
			a {display:block; color:#333;}
		}
	}
}
.sub-nav {position:relative; background:$grey; padding:20px 0; border-top:1px solid #FFF; margin:0;
	// &:after{content:""; z-index:0; position:absolute; right:-1%; top:0; width:200%; height:80px; @include transform(rotate(2deg)); display:block; background:$grey;}
	.container {position:relative; z-index:1;}
}

.side-nav {background:$lightgrey;
	> ul {margin:0; padding:0; list-style:none;
		> li {
			h3 {position:relative; display:block; background:$primary; padding:15px; @include primary-light; color:#FFF; margin:0; font-size:16px;
				&:after {content: ""; position: absolute; right: 0; top: 0; width: 0; height: 0; border-style: solid; border-width:0px; border-color: transparent #FFF transparent transparent;}
				span {@include primary-bold;}
				i{color:#fff;}
			}
			> ul {position:relative; padding:0; margin:0; list-style:none;  border-right: 5px solid $grey;
				li {
					&.active {
						a {border-right:5px solid $primary; margin-right:-5px; }
					}
					a {display:block; padding:10px 15px; color:$darkgrey;
						&:hover, &:focus, &:active {border-right:5px solid #999; margin-right:-5px; text-decoration:none;}
					}
				}
			}
		}
	}
	hr{margin:0;border-top: 3px solid #eee;}
}

.nav-offcanvas{ margin-left: -15px; margin-right: -15px;padding:0; }
.spacer{height:30px;}
button.open-offcanvas{border: none; padding: 10px 60px 10px 30px; background-color: #b94e5e; color: #fff; margin-bottom: 20px;position: relative; display: block;margin-left: -15px;}
button.open-offcanvas:after{ content: ""; position: absolute; right: 0; top: 0;  width: 0; height: 0; 
border-style: solid; border-width:40px 40px 0 0; border-color: transparent #FFF transparent transparent;}
@media (min-width: 768px) {
    .nav-offcanvas{ margin-left: 0; margin-right: 0;padding:0 15px; }
    button.open-offcanvas{margin-left:15px;}
}
@media (min-width: 996px) {
    .nav-offcanvas{ display:block; margin-top:0;}
    .sub-nav{margin:0 0 30px;}
    .side-nav > ul > li h3:after{border-width:0 47px 47px 0;}
}