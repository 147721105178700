.calendar {padding:25px 0;
	#list {
		.date {width:60px; text-align:center; border-radius:10px; border:1px solid $grey; overflow:hidden;
			.date-month {background:$primary; color:#FFF; padding:2px 0;}
			.date-day {color:$primary; padding:4px 0; font-size:24px; line-height:1; @include primary-bold;}
		}
	}
	#calendar {
		table {margin:0;
			tr {
				th {background:$primary; color:#FFF; @include primary; font-weight:normal;}
				td {}
			}
		}
	}
}
