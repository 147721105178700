body {background:#f7f7f7;}
.page {background:#FFF;}
@media (min-width: 1200px) {
	.page {width:1700px; max-width:100%; position:absolute; top:0; left:0; right:0; margin:0 auto; box-shadow:0 0 16px 0 rgba(0,0,0,0.15); overflow:hidden;}
}

.main-content { background:#FFF; padding:0 0 80px;}
@media (min-width: 768px) {
	.main-content {min-height:600px;}
}

form {
	label {@include primary; font-size:14px; color:$secondary;}
	.form-group {
		.form-control {border-radius:0;}
	}
}
.btn-group {margin:10px 0;}

.btn {border-radius:0; @include primary; @include transition(all 0.3s ease-in-out);
	&.btn-default {
		&.btn-fill {}
	}
	&.btn-primary {background:none; border:1px solid $primary; color:$primary;
		&.btn-fill {background:$primary; color:#FFF;}
		&:hover{
			background:$primary; border-color:#FFF; color:#FFF;
		}
	}
	&.btn-info { background:none; border:1px solid $blue; color:$blue;
		&.btn-fill {background:$blue; color:#FFF;}
		&:hover, &:active {background:$blue; border-color:#FFF; color:#FFF;}
	}
	&.btn-white{
		border: 3px solid #fff; color: #fff; font-size:20px; padding:6px 40px;
		&:hover, &:active {background:rgba(255,255,255,0.8); border-color:#FFF; color:$darkgrey;}
	}
}

.label {@include primary-light;
	&.label-default {background:$blue;
		&.label-nobkg {background:none; border:1px solid $blue;}
	}
	&.label-primary {background:$primary;
		&.label-nobkg {background:none; border:1px solid $primary;}
	}
}

blockquote {border-color:$grey;
	h3 {margin:0 0 5px; }
}
img {
	&.img-right, &.float-right, &.img-left, &.float-left {max-width:100%; max-height:400px; margin:20px 0;}
	&.img-full-width {width:100% !important; height:auto !important;}
}
@media (min-width: 768px) {
	img {
		&.img-right, &.float-right {float:right; max-width:40%; max-height:400px; margin:0 0 20px 20px;}
		&.img-left, &.float-left {float:left; max-width:40%; max-height:400px; margin:0 20px 20px 0;}
	}
}

i.icon {
	&.icon-4x {color:$secondary;}
}

a#scroll-top {position:fixed; height:40px; width:40px; bottom:5px; right:5px; background:rgba(0,0,0,0.35); z-index:10; text-align:center; color:rgba(255,255,255,0.5); transition: all 0.3s ease;
	&:hover {background:rgba(0,0,0,0.75); color:rgba(255,255,255,0.9)}
	i {font-size:32px; line-height:40px;}
}

@media (min-width: 768px) {
	a#scroll-top {bottom:15px; right:15px}
}


.table {height:100%; width:100%; display:table; vertical-align:middle;
	.table-cell {position:relative; height:100%; width:100%; display:table-cell; vertical-align:middle;
		&.table-cell-bottom {vertical-align:bottom;}
	}
}
table {margin:20px 0;
	&.table-centered{
		tr {text-align:center;}
	}
	&.table-striped {
		> tbody {
			> tr:nth-child(odd){background-color:#F1F1F1;}
		}
	}
	tr {
		th {@include secondary; color:$primary; font-size:18px;}
	}
	tr{
		&.active {
			td {@include primary-bold;
				&+td {color:$primary;}
			}
		}
		td{
			&.table-highlight {color:$primary;}
			small {
				strong {color:$darkgrey; @include primary-bold;}
			}
		}
	}
}


.label{
	border-radius:0;
}

span.req {
    color: #B94E5F;
}

.flex-inner {
	display: flex;
}

.placeholder {
	visibility: hidden;
	pointer-events: none;
}

.placeholder + .align-bottom {
	position: absolute;
	bottom: 0;
	left: 15px;
	right: 15px;
}

@media (min-width: 768px) {
	.row.equal {
		display: flex;
		flex-wrap: wrap;
	}
}
